import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { FullPageSpinner } from 'components/lib';
import { useHabitsQuery } from 'api/habits';
import { NoHabitsScreen } from 'screens/no-habits';

import codesnapScreenshot from './code_snap.png';

function GameScreen() {
  const { isLoading, data: habits } = useHabitsQuery();

  if (isLoading) return <FullPageSpinner />;

  if (!habits.length) return <NoHabitsScreen />;

  const navigateToURL = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <img src={codesnapScreenshot} alt="CodeSnap Game Screenshot" style={{ width: '100%', height: 'auto' }} />
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Learn programming in an interactive way. Build code blocks and learn coding by flying your aeroplane.
          You can simply type move=1,2 to fly in x axis to 1 and y axis to 2. And there is so much more!!!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigateToURL('https://codesnap.scoreexams.com/')}
          style={{ marginTop: '10px' }}
        >
          Play CodeSnap
        </Button>
      </Box>
    </Box>
  );
}

export { GameScreen };