import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@material-ui/core';

import {
  Form,
  FormBody,
  FormButton,
  FormErrorText,
  FormHeader,


  FormPrimaryText,
} from 'components/form';
import { useAuth } from 'context/auth-context';
import { signInSchema } from 'data/constraints';
import { useTranslation, translations } from 'translations';
import { useForm } from 'react-hook-form';
import { useAsync } from 'utils/hooks';


function SignInScreen() {
  const t = useTranslation(translations);

  const { signIn } = useAuth();
  const { isLoading, isError: isAuthError, run } = useAsync();

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(signInSchema),
    reValidateMode: 'onSubmit',
  });

  // Form submit
  const onSubmit = ({ email, password }) => {
    run(signIn({ email, password }));
    reset();
  };

  const errorMessages = Object.values(errors);
  const isError = isAuthError || errorMessages.length !== 0;
  //const errorMessage = authError?.message || errorMessages[0]?.message;
  let message=  useState(null);
  if (isError){
     message = "Invalid email or password. Try again"
     console.log(message);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader>
        <FormPrimaryText>{t('signIn')}</FormPrimaryText>
      
        <FormErrorText>{isError ? message : ' '}</FormErrorText>
      </FormHeader>

      <FormBody>


        <TextField
          inputRef={register}
          name="email"
          autoComplete="email"
          label={t('email')}
          placeholder={t('emailPlaceholder')}
          error={!!errors?.email}
          disabled={isLoading}
          variant="outlined"
          fullWidth
        />

        <TextField
          inputRef={register}
          name="password"
          type="password"
          autoComplete="current-password"
          label={t('password')}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          error={!!errors?.password}
          disabled={isLoading}
          variant="outlined"
          fullWidth
        />

        <FormButton type="submit" pending={isLoading}>
          {t('signIn')}
        </FormButton>

      </FormBody>
    </Form>
  );
}

export { SignInScreen };
