import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { habitSchema } from 'data/constraints';
import { TextField, CircularProgress } from '@material-ui/core';
import { CheckboxGroup } from 'components/checkbox-group';
import { useSnackbar } from 'context/snackbar-context';
import { useAddHabitMutation, useHabitsQuery } from 'api/habits';
import {
  Form,
  FormBody,
  FormButton,
  FormErrorText,
  FormHeader,
  FormPrimaryText,
} from 'components/form';
import { useLocale } from 'localization';
import { useTranslation } from 'translations';

// Import the necessary Firebase and Firestore dependencies
import firebase from 'firebase/app';
import 'firebase/firestore';

// Initialize Firestore
const firestore = firebase.firestore();

// Initial habit
const initialHabit = {
  name: '',
  description: '',
  frequency: [],
};

function FullPageSpinner({ message }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CircularProgress />
      <p style={{ marginLeft: '10px' }}>{message}</p>
    </div>
  );
}

function AddHabitScreen() {
  const navigate = useNavigate();
  const t = useTranslation();
  const { weekdays } = useLocale();
  const { openSnackbar } = useSnackbar();

  const { data: habits, isLoading } = useHabitsQuery();
  const addHabitMutation = useAddHabitMutation();

  // Form
  const { control, register, handleSubmit, errors, getValues, reset } = useForm({
    defaultValues: initialHabit,
    resolver: yupResolver(habitSchema),
  });

  const [isCreatingHabit, setIsCreatingHabit] = React.useState(false);
  const [isPlanningHabits, setIsPlanningHabits] = React.useState(false); // Add state variable for planning habits

  // Submit form
  const onSubmit = async (form) => {
    const { name: habitName, description, frequency } = form;
    const position = habits.length;

    try {
      setIsCreatingHabit(true);
      setIsPlanningHabits(true); // Set isPlanningHabits to true when starting to plan habits

      // Add the habit to the database
      await addHabitMutation.mutateAsync({ name: habitName, description, frequency, position });

      // Create a new thread with the same name as the habit name
      const user = firebase.auth().currentUser;
      const uid = user.uid;

      const newThread = {
        name: habitName,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() // Add createdAt field with server timestamp
      };

      const threadRef = await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .add(newThread);

      const threadId = threadRef.id;

      // Send user message with the habit name
      const userMessage = {
        content: habitName,
        sender: 'user',
        threadId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .doc(threadId)
        .collection('messages')
        .add(userMessage);

      // Send assistant message with the response from the POST request
      await sendAssistantMessage(habitName, threadId);

      // Show success message and reset the form
      openSnackbar('success', t('habitAdded'));
      reset(initialHabit);
      navigate('/chats');
    } catch (error) {
      console.error(error);
      openSnackbar('error', t('habitAddError'));
    } finally {
      setIsCreatingHabit(false);
      setIsPlanningHabits(false); // Set isPlanningHabits to false when done planning habits
    }
  };

  // Send assistant message
  const sendAssistantMessage = async (habitName, threadId) => {
    try {
      const habitname = "I want to learn: " + habitName
      const user = firebase.auth().currentUser;
    
      const uidnew = user.uid.toString() + habitName.toString();
      const uid = user.uid
      console.log(uid)
      const personality =
        'You are an AI habit builder called Cuty. Your job is to make the habit dream comes true. You have to split the dream to habits, tasks and notes with minimum 4 points in habtis, minimum 4 points in tasks and more than 6 points in notes.';

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}?i=${encodeURIComponent(habitname)}&p=${personality}&u=${uidnew}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          },
        }
      );

      const data = await response.json();

      const assistantMessage = {
        content: data.response,
        sender: 'assistant',
        threadId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .doc(threadId)
        .collection('messages')
        .add(assistantMessage);
    } catch (error) {
      console.error(error);
    }
  };

  // Is loading habits
  if (isLoading || isPlanningHabits) {
    return <FullPageSpinner message="Please wait while Cuty plans your habits tasks and notes..." />;
  }

  // Get array of errors from the form
  const formErrors = Object.values(errors);

  const errorText = addHabitMutation.isError
    ? // If there is an error when adding the habit it display it first
      addHabitMutation.error.message
    : // Otherwise display first form error if any
      formErrors[0]?.message;

  // Disable form actions when the habit is being added
  const disableActions = addHabitMutation.isLoading || isCreatingHabit;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader>
        <FormPrimaryText>{t('createNewHabit')}</FormPrimaryText>
        <FormErrorText>{errorText || ' '}</FormErrorText>
      </FormHeader>

      <FormBody>
        <TextField
          inputRef={register}
          name="name"
          label={t('habitNameLabel')}
          error={!!errors?.name}
          variant="outlined"
          disabled={disableActions}
          fullWidth
        />

        <TextField
          inputRef={register}
          name="description"
          label={t('habitDescriptionLabel')}
          error={!!errors?.description}
          variant="outlined"
          disabled={disableActions}
          fullWidth
        />

        <CheckboxGroup
          label={t('habitFrequencyLabel')}
          name="frequency"
          control={control}
          getValues={getValues}
          values={weekdays}
          error={!!errors?.frequency}
        />

        <FormButton type="submit" pending={disableActions}>
          {isCreatingHabit ? <CircularProgress size={24} /> : t('createHabit')}
        </FormButton>
      </FormBody>
    </Form>
  );
}

export { AddHabitScreen };