import React, { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import Markdown from 'markdown-to-jsx';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const auth = firebase.auth();
const firestore = firebase.firestore();

function ChatScreen() {
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  let firebaseimageurl = useState(null);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const loadThreads = async () => {
      try {
        const user = auth.currentUser;
        const uid = user.uid;

        const snapshot = await firestore
          .collection('conversations')
          .doc(uid)
          .collection('threads')
          .orderBy('createdAt', 'desc')
          .get();
        const fetchedThreads = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          };
        });

        setThreads(fetchedThreads);
        if (selectedThread === null && fetchedThreads.length > 0) {
          setSelectedThread(fetchedThreads[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const loadMessages = async () => {
      try {
        const user = auth.currentUser;
        const uid = user.uid;

        if (selectedThread !== null) {
          const snapshot = await firestore
            .collection('conversations')
            .doc(uid)
            .collection('threads')
            .doc(selectedThread.id)
            .collection('messages')
            .orderBy('timestamp')
            .get();
          const fetchedMessages = snapshot.docs.map((doc) => doc.data());

          setMessages(fetchedMessages);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadThreads();
    loadMessages();
  }, [selectedThread]);

  const createThread = async () => {
    await setIsImageUploaded(true);
    try {
      const user = auth.currentUser;
      const uid = user.uid;
      console.log(uid);

      const newThread = {
        name: `Thread ${threads.length + 1}`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() // Add createdAt field with server timestamp
      };

      const docRef = await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .add(newThread);

      setThreads([...threads, { id: docRef.id, ...newThread }]);
      setSelectedThread({ id: docRef.id, ...newThread });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteThread = async (threadId) => {
    try {
      const user = auth.currentUser;
      const uid = user.uid;

      await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .doc(threadId)
        .delete();

      setThreads(threads.filter((thread) => thread.id !== threadId));
      setSelectedThread(null);
      setMessages([]);
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = async (message) => {
    try {
      const user = auth.currentUser;
      const uid = user.uid;

      await firestore
        .collection('conversations')
        .doc(uid)
        .collection('threads')
        .doc(selectedThread.id)
        .collection('messages')
        .add({
          content: message.content,
          sender: message.sender,
          threadId: selectedThread.id,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
      console.error(error);
    }
  };


  const sendAssistantMessageWithImageUrl = async (message) => {
    try {
      //console.log(selectedThread)

      const isFirstConversation = threads.length === 0;

      if (isFirstConversation) {
        await createThread();
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL_MATH}?i=${encodeURIComponent(
          message
        )}&u=${encodeURIComponent(
          firebaseimageurl
        )}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN_IMAGE}`,
          },
        }
      );

      const data = await response.json();
      await sendAssistantMessage(data.response)

   
    } catch (error) {
      console.error(error);
    }
  };

  const sendAssistantMessage = async (message) => {

      try {
        const user = auth.currentUser;
        const uid = user.uid + selectedThread.name;
        console.log(uid)
        const personality = "You are are an AI habit builder called Cuty, developed by data scientist from Score exams from India. You are helping students to build habits inside the habitat, habit tracking application.";
       // console.log(selectedThread)

        const isFirstConversation = threads.length === 0;

        if (isFirstConversation) {
          await createThread();
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}?i=${encodeURIComponent(
            message
          )}&p=${personality}y&u=${uid}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            },
          }
        );

        const data = await response.json();

        const assistantMessage = {
          content: data.response,
          sender: 'assistant',
          threadId: selectedThread.id,
        };
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        sendMessage(assistantMessage);
      } catch (error) {
        console.error(error);
      
    }
  };

  const handleSendMessage = () => {
    if (!selectedThread || userInput.trim() === '') {
      return;
    }

    const userMessage = {
      content: userInput,
      sender: 'user',
      threadId: selectedThread.id,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    sendMessage(userMessage);
    sendAssistantMessage(userInput);

    setUserInput('');
  };

  const handleThreadClick = (thread) => {
    setSelectedThread(thread);
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleCopyResponse = (response) => {
    navigator.clipboard.writeText(response);
  };

  const handleDeleteThread = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    deleteThread(selectedThread.id);
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const user = auth.currentUser;
      const uid = user.uid;
      const threadval = selectedThread.name;
      //console.log(threadval);
      const idval = uid + '_' + threadval;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`images/${idval}`);

      setUploadingImage(true);

      await fileRef.put(file);

      const imageUrl = await fileRef.getDownloadURL();
      firebaseimageurl = imageUrl;

      //console.log(imageUrl);
      const imageurl = `![Image Description](${imageUrl})`;
      const userMessage = {
        content: imageurl,
        sender: 'user',
        threadId: selectedThread.id,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);
      sendMessage(userMessage);

      setUserInput('');

      setIsImageUploaded(true);
      sendAssistantMessageWithImageUrl("Give me a detailed description of the image?");
      event.target.value = ''; // Reset the value of the file input element
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingImage(false);
    }
  };

  useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [selectedThread]);

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 270px)',
              overflowY: 'auto',
            }}
          >
            {threads.map((thread) => (
              <Box
                key={thread.id}
                sx={{
                  marginBottom: '10px',
                  backgroundColor: selectedThread && selectedThread.id === thread.id ? '#f0f0f0' : 'transparent',
                  borderRadius: '8px',
                  padding: '5px',
                  cursor: 'pointer',
                  color: selectedThread && selectedThread.id === thread.id ? 'blue' : 'inherit',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => handleThreadClick(thread)}
              >
                <Typography variant="body1">{thread.name}</Typography>
                <DeleteIcon onClick={handleDeleteThread} style={{ cursor: 'pointer' }} />
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={createThread}
            style={{
              marginBottom: '10px',
              textTransform: 'none',
              width: '100%',
            }}
          >
            New Conversation
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 200px)',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            overflow: 'auto',
          }}
          ref={chatWindowRef}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  marginBottom: '10px',
                  flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                }}
              >
                <Markdown
                  children={message.content}
                  options={{
                    overrides: {
                      p: Typography,
                      span: Typography,
                      h1: Typography,
                      h2: Typography,
                      h3: Typography,
                      h4: Typography,
                      h5: Typography,
                      h6: Typography,
                      strong: Typography,
                      em: Typography,
                      code: Typography,
                      a: ({ href, children }) => (
                        <Typography component="a" href={href} target="_blank">
                          {children}
                        </Typography>
                      ),
                    },
                  }}
                  sx={{
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: message.sender === 'assistant' ? 'blue' : '#4caf50',
                    color: message.sender === 'assistant' ? '#000' : '#fff',
                    maxWidth: '70%',
                  }}
                />
                {message.sender === 'assistant' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCopyResponse(message.content)}
                    style={{
                      marginLeft: '10px',
                      borderRadius: '8px',
                      textTransform: 'none',
                      boxShadow: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <FileCopyOutlinedIcon />
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <input
            type="text"
            value={userInput}
            onChange={handleUserInput}
            onKeyDown={handleKeyDown}
            style={{
              padding: '10px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              width: '100%',
              flexGrow: 1,
              color: 'blue',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            style={{
              marginLeft: '10px',
              padding: '10px',
              borderRadius: '8px',
              backgroundColor: '#4caf50',
              color: '#fff',
              textTransform: 'none',
              boxShadow: 'none',
              cursor: 'pointer',
            }}
          >
            <SendIcon />
          </Button>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="upload-image-input"
          />
          <label htmlFor="upload-image-input">
            <Button
              component="span"
              variant="contained"
              color="secondary"
              style={{ marginLeft: '10px', borderRadius: '8px' }}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>

          <Button
            variant="contained"
            color={isImageUploaded ? 'primary' : 'default'}
            onClick={() => setIsImageUploaded(!isImageUploaded)}
            style={{ marginLeft: '10px' }}
          >
            {isImageUploaded ? 'Image ON' : 'ImageOFF'}
          </Button>
          {uploadingImage && <CircularProgress color="primary" size={100} style={{ marginLeft: '10px' }} />}
        </Box>
      </Grid>

      {showConfirmation && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Box
            sx={{
              width: '300px',
              padding: '20px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: '20px', color: 'red' }}>
              Are you sure you want to delete the thread?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
                Yes
              </Button>
              <Button variant="contained" color="secondary" onClick={handleCancelDelete}>
                No
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export { ChatScreen };